import {Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ShellPageTitleDirective} from "../shell-page-title.directive";
import {ShellHeaderToolbarLeftDirective} from "../shell-header-toolbar-left.directive";
import {ShellHeaderToolbarRightDirective} from "../shell-header-toolbar-right.directive";
import {ActivatedRoute} from "@angular/router";
import {Observable, of} from "rxjs";
import {MenuItem} from "primeng/api";
import {AppShellItem, AppShellRoutingProvider} from "@fiscalteam/ngx-nitro-services";

@Component({
  selector: 'adm-app-shell-page',
  templateUrl: './app-shell-page.component.html',
  styleUrls: ['./app-shell-page.component.scss']
})
export class AppShellPageComponent implements OnInit, OnDestroy {

  /**
   * When the page is a 'shell' that should be available in the navigation breadcrumb,
   * this parameter must be set to provide the information to display
   */
  @Input()
  shellItem?: AppShellItem;

  /**
   * When true, the title will not be displayed. Toolbars left & right are still included, but may use less vertical space
   */
  @Input()
  hideTitleIfNested = false;

  @ContentChild(ShellPageTitleDirective, {
    read: TemplateRef
  })
  titleTemplateRef?: TemplateRef<any>;
  @ContentChild(ShellHeaderToolbarLeftDirective, {
    read: TemplateRef
  })
  toolbarLeftTemplateRef?: TemplateRef<any>;
  @ContentChild(ShellHeaderToolbarRightDirective, {
    read: TemplateRef
  })
  toolbarRightTemplateRef?: TemplateRef<any>;


  hasNestedShells$: Observable<boolean> = of(false);
  isNestedShells$: Observable<boolean> = of(false);
  breadcrumbMenuModel$: Observable<MenuItem[]> = of([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private shellRoutingService: AppShellRoutingProvider,
  ) {
  }

  ngOnInit(): void {
    if (this.shellItem != null) {
      this.shellRoutingService.onShellInit(this.shellItem, this.activatedRoute);
      this.hasNestedShells$ = this.shellRoutingService.hasNestedShell$(this.shellItem);
      this.isNestedShells$ = this.shellRoutingService.isNestedShell$(this.shellItem);
      this.breadcrumbMenuModel$ = this.shellRoutingService.breadcrumbMenuModel$;
    }
  }

  ngOnDestroy() {
    if (this.shellItem != null) {
      this.shellRoutingService.onShellDestroy(this.shellItem);
    }
  }

}
