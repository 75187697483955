<adm-app-shell-page>
  <ng-template admShellPageTitle>
    Aperçu des pièces comptables
  </ng-template>

  <div class="accounting-data-dashboard-content h-full w-full">
      <nit-accounting-statistics-data [baseFilter]="{}"
                                      [showInfo]="showStatusInfo"
                                      [availableRuleActions]="availableRuleActions"
                                      [showConfigured]="showConfigured"
                                      [showCompleted]="showCompleted"
                                      [showTrustee]="showTrustee"
                                      [documentsRouterLinkFactory]="routeUtils.customerDocumentListForFiltersFactory"
                                      [transactionsRouterLinkFactory]="routeUtils.customerTransactionListForFiltersFactory"
                                      [ruleRouterLinkFactory]="routeUtils.nitroRuleListForFiltersFactory"
                                      [customerdocumentsRouterLinkFactory]="routeUtils.customerdocumentsRouterLinkFactory"
                                      [customertransactionsRouterLinkFactory]="routeUtils.customertransactionsRouterLinkFactory"
      ></nit-accounting-statistics-data>
  </div>
</adm-app-shell-page>
