<div class="app-shell flex h-screen relative">
  <div class="shell-messages block w-full absolute top-0 m-auto z-4 flex align-items-center flex-column">
    <div class="relative w-7 max-w-full">
      <p-messages [value]="(messages$ | async) || []"
                  (valueChange)="onMessageChange($event)"
      >
      </p-messages>
    </div>
  </div>

  <div class="menu flex-0 relative">
    <button (click)="menuCollapsed = !menuCollapsed" *ngIf="menuCollapsed; else menu"
            [style]="{marginTop: '1rem'}"
            class="p-button-outlined p-button-plain p-button-rounded p-button-sm absolute surface-overlay"
            icon="pi pi-chevron-right"
            pButton
            type="button"
    ></button>
    <ng-template #menu>
      <adm-app-shell-menu></adm-app-shell-menu>

      <button (click)="menuCollapsed = !menuCollapsed" [style]="{top: '1rem', right: '-.8rem'}"
              class="p-button-outlined p-button-plain p-button-rounded p-button-sm absolute surface-overlay"
              icon="pi pi-chevron-left"
              pButton
              type="button"
      ></button>

    </ng-template>
  </div>

  <div class="content flex-1 ml-4 h-full relative overflow-hidden">
    <router-outlet></router-outlet>
  </div>
</div>


<p-toast (onClose)="onOptimsticLockModalClose()"
         [key]="ModalKeys.OPTIMISTIC_LOCK_MODAL_KEY"
         [preventOpenDuplicates]="true"
         position="center"
         styleClass="confirm-toast"
>

  <ng-template let-message pTemplate="message">
    <div class="optimistic-lock">
      <div class="header">
        <i class="pi pi-exclamation-triangle"></i>
        <span class="m-2 text-lg font-bold">{{ message.summary }}</span>
      </div>

      <p>
        La version du formulaire (ou d'une partie du formulaire) que vous voulez enregistrer semble avoir été
        modifiée depuis qu'il a été chargé. Cela peut arriver par exemple lorsque plusieurs personnes modifient
        le même formulaire en même temps.
      </p>

      <div class="actions">
        <button (click)="onOptimsticLockRefetch(message)" class="ui-button-primary" label="Recharger"
                nitForceFocus
                pButton type="button"></button>

        <!-- TODO: handle reloading server version while preserving local changes and dusplaying diff (changed fields) to the user -->
        <!-- overwrite disabled to prevent data loss-->
        <!--        <button type="button" pButton (click)="onOptimistickLockOverwrite(message)"-->
        <!--                label="Écraser" class="ui-button-danger"></button>-->

      </div>
    </div>
  </ng-template>

</p-toast>


