<adm-app-shell-page>
  <ng-template admShellPageTitle>
  Utilisateurs Nitro
  </ng-template>
  <ng-template admShellHeaderToolbarLeft>
    <nit-button-link [routerLink]="['../new']"
                     buttonStyleClass="p-button-text "
                     class="flex-0 mx-3"
                     icon="pi pi-plus"
                     label="Ajouter un utilisateur"
    ></nit-button-link>
  </ng-template>
  <div class="user-list w-full h-full overflow-hidden">
    <nit-nitro-user-list [initialColumns]="columns"
                         [initialSearchFilter]="{}"
                         (rowClick)="onRowClick($event)"
                         [selectable]="false"
                         [showFilterRow]="true"
                         [showColumnFilters]="true"
                         #component
    ></nit-nitro-user-list>
  </div>
</adm-app-shell-page>
