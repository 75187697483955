import {BehaviorSubject, firstValueFrom, forkJoin, from, Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {DefaultService} from "@fiscalteam/nitro-domain-client";
import {AppShellMenuItem} from "./app-shell/app-shell-menu/app-shell-menu-item";
import {AdminFrontConfigService} from "../config/admin-front-config.service";

@Injectable()
export class ShellMenuService {

  menu$ = new BehaviorSubject<AppShellMenuItem[]>([]);

  constructor(
    private apiService: DefaultService,
    private configService: AdminFrontConfigService,
  ) {
    // Our menu is a mutable instance for the whole app lifecycle.
    this.menu$.next(this.configService.createMainMenu());
    this.updateMenu$();
  }

  async updateMenu$(): Promise<AppShellMenuItem[]> {
    const items = this.menu$.getValue();
    const menuItems$List: Observable<AppShellMenuItem>[] = items.map(i => from(this.updateMenuItem$(i)));
    const itemsLits$: Observable<AppShellMenuItem[]> = menuItems$List.length === 0 ? of([]) : forkJoin(menuItems$List);
    const newItems = await firstValueFrom(itemsLits$);
    this.menu$.next(newItems);
    return Promise.resolve(newItems);
  }

  async updateMenuItems$(items: AppShellMenuItem[], recursive = true): Promise<AppShellMenuItem[]> {
    return Promise.all(items.map(i => this.updateMenuItem$(i, recursive)));
  }

  async updateMenuItem$(item: AppShellMenuItem, recursive = true): Promise<AppShellMenuItem> {
    if (item.totalCountBadgeFunction) {
      const badgeCount = await firstValueFrom(item.totalCountBadgeFunction(this.apiService))
      item.badge = badgeCount > 0 ? `${badgeCount}` : undefined;
    }

    if (recursive) {
      const subItems = item.items || [];
      const updatedSubItem$List: Observable<AppShellMenuItem>[] = subItems.map(i => from(this.updateMenuItem$(i)));
      const updatedSubItemList$: Observable<AppShellMenuItem[]> = updatedSubItem$List.length === 0 ? of([]) : forkJoin(updatedSubItem$List);
      const newSubItems = await firstValueFrom(updatedSubItemList$);

      item.items = newSubItems.length > 0 ? newSubItems : undefined;
    }

    return Promise.resolve(item);
  }


}
