import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, EMPTY, map, Observable, of, shareReplay, switchMap, take, tap} from "rxjs";
import {WsAccountingDataProblem, WsFieldIdentificationRequest, WsFieldIdentificationRequestStatus, WsFieldIdentificationValue, WsFieldIdentificationValueSearch, WsFieldIdentificationValueStatus, WsLogLineSearch, WsResultPageWsFieldIdentificationValue, WsResultPageWsLogLine} from "@fiscalteam/nitro-domain-client";
import {AccountingDataProblemService, FieldIdentificationRequestService, FieldIdentificationValueService, IndexingFieldHandlersService, IndexingFieldModel, NitroLoggedUserService, NitroMessageService, Pagination, PaginationUtils} from "@fiscalteam/ngx-nitro-services";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'adm-field-request-details-route',
  templateUrl: './field-request-details-route.component.html',
  styleUrls: ['./field-request-details-route.component.scss']
})
export class FieldRequestDetailsRouteComponent implements OnInit {

  reloadSource$ = new BehaviorSubject<boolean>(false);
  fieldRequest$: Observable<WsFieldIdentificationRequest | undefined> = EMPTY;

  dataProblem$: Observable<WsAccountingDataProblem | undefined> = EMPTY;
  adminIndexerValue$: Observable<IndexingFieldModel | undefined> = EMPTY;

  logDialogVisible = false;
  logLineSearchFunction?: (filter: WsLogLineSearch, pagination: Pagination) => Observable<WsResultPageWsLogLine | undefined>;

  fieldValueSearchFunction?: (filter: WsFieldIdentificationValueSearch, pagination: Pagination) => Observable<WsResultPageWsFieldIdentificationValue>;

  WsFieldIdentificationRequestStatus = WsFieldIdentificationRequestStatus;
  WsFieldIdentificationValueStatus = WsFieldIdentificationValueStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: NitroMessageService,
    private fieldIdentificationRequestService: FieldIdentificationRequestService,
    private fieldIdentificationValueService: FieldIdentificationValueService,
    private loggedUserService: NitroLoggedUserService,
    private dataProblemService: AccountingDataProblemService,
    private fieldHandlerService: IndexingFieldHandlersService,
  ) {
  }

  ngOnInit(): void {
    const routeRequest$ = this.activatedRoute.data.pipe(
      map(d => d['fieldIdentificationRequest']),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.fieldRequest$ = combineLatest([
      routeRequest$, this.reloadSource$
    ]).pipe(
      map((r: [WsFieldIdentificationRequest, any]) => r[0]),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.logLineSearchFunction = (f, p) => this.searchLogLines(f, p);
    this.fieldValueSearchFunction = (f, p) => this.searchFieldValues(f, p);

    this.dataProblem$ = this.fieldRequest$.pipe(
      switchMap(r => this.findRequestProblem$(r)),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.adminIndexerValue$ = combineLatest([
      this.dataProblem$, this.fieldRequest$,
    ]).pipe(
      switchMap((r: [WsAccountingDataProblem | undefined, WsFieldIdentificationRequest | undefined]) => this.checkCreateProblemFieldModel$(r[0], r[1])),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  onRefreshClick() {
    this.fieldRequest$.pipe(
      take(1),
      switchMap(req => req ? this.fieldIdentificationRequestService.refreshFieldIdentificationRequest$({id: req.id!}) : of(undefined))
    ).subscribe({
      next: r => {
        this.reloadSource$.next(true);
      },
      error: e => {
        this.messageService.showError(`Impossible de rafraîchir le champ`, e);
      }
    });
  }

  onSaveValueCLick() {
    this.adminIndexerValue$.pipe(
      take(1)
    ).subscribe(f => this.saveFieldModelValue(f));

  }

  private searchLogLines(filter: WsLogLineSearch, pagination: Pagination) {
    return this.fieldRequest$.pipe(
      take(1),
      switchMap(req => req ? this.fieldIdentificationRequestService.searchLogLines$({id: req.id!}, filter, pagination) : of(undefined))
    );
  }

  private searchFieldValues(filter: WsFieldIdentificationValueSearch, pagination: Pagination) {
    return this.fieldRequest$.pipe(
      take(1),
      switchMap(req => this.searchFieldValuesWithRequest$(req, filter, pagination))
    );
  }

  private searchFieldValuesWithRequest$(req: WsFieldIdentificationRequest | undefined, filter: WsFieldIdentificationValueSearch, pagination: Pagination) {
    if (req) {
      const updatedFilter: WsFieldIdentificationValueSearch = Object.assign({}, filter, {
        identificationRequestSearch: {
          exactFieldIdentificationRequestWsRef: {id: req.id},
        }
      } as Partial<WsFieldIdentificationValueSearch>);
      return this.fieldIdentificationValueService.searchFieldIdentificationValues$(updatedFilter, pagination);
    } else {
      return of({
        itemList: [],
        totalCount: 0
      } as WsResultPageWsFieldIdentificationValue);
    }
  }

  private checkCreateProblemFieldModel$(problem: WsAccountingDataProblem | undefined,
                                        request: WsFieldIdentificationRequest | undefined): Observable<IndexingFieldModel | undefined> {
    if (request == null || request.id == null) {
      return of(undefined);
    }
    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    return this.fieldIdentificationValueService.createIndexingFieldModel$({id: request.id!}, {id: loggedUser.id!}, true, false).pipe(
      map(v => {
        const displayedValue = v.allBackendValues.find(v => v.valueStatus === WsFieldIdentificationValueStatus.Displayed);
        if (displayedValue) {
          v.indexerValue$.next(displayedValue);
          return v;
        }
        if (problem == null || problem.id == null) {
          // Wa cannot create a new value
          return v;
        }
        const value = v.indexerValue$.getValue();
        if (value && (value.valueStatus === WsFieldIdentificationValueStatus.Displayed
          || value.valueStatus === WsFieldIdentificationValueStatus.Problem)) {
          return v;
        }
        v.indexerValue$.next({
          id: undefined,
          valueStatus: WsFieldIdentificationValueStatus.Displayed,
          identificationRequestWsRef: {id: request.id!},
          resolvedProblemRef: !problem ? undefined : {id: problem.id},
          identifierUserWsRef: {id: loggedUser.id!},
        });
        return v;
      })
    )
  }

  saveFieldModelValue(model: IndexingFieldModel | undefined) {
    if (model == null) {
      return;
    }
    const indexerValue = model.indexerValue$.getValue();
    if (indexerValue == null) {
      return;
    }

    const displayedValue$ = indexerValue.id == null ? this.fieldIdentificationValueService.saveFieldIdentificationValue$(indexerValue) : of(indexerValue);
    const field = model.field;
    const typedValue = model.typedValueUpdateSource$.getValue();

    return displayedValue$.pipe(
      switchMap(v => this.fieldHandlerService.setFieldValueHandlingErrors$({id: v.id!}, field, typedValue, false)),
      tap(v => model.indexerValue$.next(v as WsFieldIdentificationValue)),
      switchMap(v => this.dataProblem$.pipe(take(1))),
      switchMap(p => this.saveFieldModelValueWithProblem$(model.indexerValue$.getValue()!, p!)),
      switchMap(v => this.fieldRequest$.pipe(take(1))),
      switchMap(req => this.fieldIdentificationRequestService.refreshFieldIdentificationRequest$({id: req!.id!}))
    ).subscribe({
      next: v => {
        this.reloadSource$.next(true);
      }
    })
  }

  private findRequestProblem$(request: WsFieldIdentificationRequest | undefined): Observable<WsAccountingDataProblem | undefined> {
    if (request == null || request.id == null) {
      return of(undefined);
    }
    return this.dataProblemService.searchAccountingDataProblems$({
      wsFieldIdentificationRequestSearch: {
        exactFieldIdentificationRequestWsRef: {id: request.id},
      }
    }, PaginationUtils.newPagination(0, 10)).pipe(
      switchMap(r => {
        const foundRefList = r.itemList || [];
        if (foundRefList.length == 0) {
          return of(undefined);
        }
        // resolve at most 1 problem... //FIXME?
        return this.dataProblemService.getAccountingDataProblem$({id: foundRefList[0].id!})
      })
    );
  }

  private saveFieldModelValueWithProblem$(indexerValue: WsFieldIdentificationValue, problem?: WsAccountingDataProblem | undefined) {
    if (problem && problem.id) {
      indexerValue.resolvedProblemRef = {id: problem.id};
      indexerValue.resolutionDescription = `Valeur envoyées via app admin`;
    }
    indexerValue.valueStatus = WsFieldIdentificationValueStatus.Submitted;
    return this.fieldIdentificationValueService.saveFieldIdentificationValueHandlingErrors$(indexerValue, true, false);
  }
}
