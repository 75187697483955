import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppShellPageComponent} from "./app-shell-page/app-shell-page.component";
import {ShellHeaderToolbarRightDirective} from "./shell-header-toolbar-right.directive";
import {ShellHeaderToolbarLeftDirective} from "./shell-header-toolbar-left.directive";
import {ShellPageTitleDirective} from "./shell-page-title.directive";
import {ThirdpartyDetailsFormComponent} from "./thirdparty-details-form/thirdparty-details-form.component";
import {LabelsInputModule, ThirdpartyAutocompleteModule, ThirdpartyEntityAutocompleteModule, ThirdpartyEntityFormModule, ThirdpartyFormModule, ThirdpartyIdentifiersInputModule} from "@fiscalteam/ngx-nitro-services";
import {FormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {ButtonModule} from "primeng/button";
import {ThirdpartyReplacementPopupComponent} from "./thirdparty-replacement-popup/thirdparty-replacement-popup.component";
import {CheckboxModule} from "primeng/checkbox";
import {BreadcrumbModule} from "primeng/breadcrumb";

@NgModule({
  declarations: [
    AppShellPageComponent,
    ShellPageTitleDirective,
    ShellHeaderToolbarLeftDirective,
    ShellHeaderToolbarRightDirective,
    ThirdpartyDetailsFormComponent,
    ThirdpartyReplacementPopupComponent,
  ],
  exports: [
    AppShellPageComponent,
    ShellPageTitleDirective,
    ShellHeaderToolbarLeftDirective,
    ShellHeaderToolbarRightDirective,
    ThirdpartyDetailsFormComponent,
    ThirdpartyReplacementPopupComponent,
  ],
  imports: [
    CommonModule,
    LabelsInputModule,
    ThirdpartyIdentifiersInputModule,
    FormsModule,
    ThirdpartyFormModule,
    DialogModule,
    ThirdpartyAutocompleteModule,
    ButtonModule,
    CheckboxModule,
    ThirdpartyEntityAutocompleteModule,
    BreadcrumbModule,
    ThirdpartyEntityFormModule
  ]
})
export class AdminFrontSharedModule {
}
