import {Component, OnInit} from '@angular/core';
import {ErrorService} from "@fiscalteam/ngx-nitro-services";
import {debounceTime, Observable, shareReplay} from "rxjs";
import {VERSION} from "../version";

@Component({
  selector: 'adm-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {


  backendAvailable$: Observable<boolean>;
  routeLoaded = false;
  version = VERSION;

  constructor(
    private errorService: ErrorService,
  ) {
    this.backendAvailable$ = this.errorService.getBackendAvailable$().pipe(
      debounceTime(100), // Force async for change detection
      shareReplay({refCount: true, bufferSize: 1})
    )
  }

  async ngOnInit(): Promise<void> {
  }

  onBackendUnavailableClick() {
    if (window && window.location) {
      window.location.reload();
    }
  }
}
