import {Directive} from '@angular/core';

@Directive({
  selector: '[admShellHeaderToolbarLeft]'
})
export class ShellHeaderToolbarLeftDirective {

  constructor() {
  }

}
