import {Component, OnInit} from '@angular/core';
import {NitroUserColumn, NitroUserColumns} from "@fiscalteam/ngx-nitro-services";


@Component({
  selector: 'adm-nitro-user',
  templateUrl: './nitro-user-route.component.html',
  styleUrl: './nitro-user-route.component.scss'
})
export class NitroUserRouteComponent implements OnInit{

  columns: NitroUserColumn[] = [
    NitroUserColumns.id,
    NitroUserColumns.creationTime,
    NitroUserColumns.name,
  ];
  lastRowClick: any;

  onRowClick(value: any) {
    this.lastRowClick = value;
  }

  ngOnInit(): void {
  }

}
