import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AccountingStatisticsDataModule, ButtonLinkModule, DataStatusStatsPanelModule, DocumentFieldModule, FieldProblemTypeModule, FieldRequestStatusModule, FieldRequestValueModule, FieldValueListModule, ForceFocusDirectiveModule, IndexingFieldInputModule, JsonViewerModule, LogLinesListModule, NitroAuthModule, nitroAuthWithAngularOauth2Oidc, NitroConfigModule, nitroConfigWithKeyValues, NitroEnvBannerModule, NitroServiceModule} from "@fiscalteam/ngx-nitro-services";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {AppShellComponent} from "./shell/app-shell/app-shell.component";
import {AppShellMenuComponent} from "./shell/app-shell/app-shell-menu/app-shell-menu.component";
import {MessagesModule} from "primeng/messages";
import {FormsModule} from "@angular/forms";
import {FieldRequestDetailsRouteComponent} from './shell/field-request-details-route/field-request-details-route.component';
import {FieldRequestDetailsFormComponent} from './shell/field-request-details-route/field-request-details-form/field-request-details-form.component';
import {AdminFrontSharedModule} from "./shared/admin-front-shared.module";
import {AccountingDataDashboardRouteComponent} from './shell/accounting-data-dashboard-route/accounting-data-dashboard-route.component';
import {CodaBatchResultNavigatorComponent} from './shell/coda-batch-result-navigator/coda-batch-result-navigator.component';
import {ButtonModule} from "primeng/button";
import {ToastModule} from "primeng/toast";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BadgeModule} from "primeng/badge";
import {DialogModule} from "primeng/dialog";
import {PanelModule} from "primeng/panel";
import {ToggleButtonModule} from "primeng/togglebutton";
import {DropdownModule} from "primeng/dropdown";
import {PaginatorModule} from "primeng/paginator";
import {environment} from "../environments/environment";
import {TabViewModule} from "primeng/tabview";
import {CodaBatchStepResultComponent} from './shell/coda-batch-step-result/coda-batch-step-result.component';
import {DefaultAdminFrontConfigs} from "./config/admin-front-default-config";
import {NitroUserModule} from "./nitro-user/nitro-user.module";

@NgModule({ declarations: [
        AppComponent,
        AppShellComponent,
        AppShellMenuComponent,
        FieldRequestDetailsRouteComponent,
        FieldRequestDetailsFormComponent,
        AccountingDataDashboardRouteComponent,
        // CodaBatchResultNavigatorComponent,
        // CodaBatchStepResultComponent,
    ],
    exports: [
        AdminFrontSharedModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NitroConfigModule.forRoot({
            domainApiClient: true,
            applicationName: "nitro-admin-front",
            configValues: environment.configValues,
            asyncConfigValuesUri: environment.runtimeConfigPath,
            extraFeatures: [
                nitroConfigWithKeyValues(DefaultAdminFrontConfigs, 0)
            ]
        }),
        NitroAuthModule.forRoot({
            implementation: nitroAuthWithAngularOauth2Oidc(),
            httpInterceptor: true,
        }),
        NitroServiceModule.forRoot(),
        AdminFrontSharedModule,
        FormsModule,
        MessagesModule,
        ButtonModule,
        ToastModule,
        ForceFocusDirectiveModule,
        ButtonLinkModule,
        OverlayPanelModule,
        DocumentFieldModule,
        BadgeModule,
        IndexingFieldInputModule,
        FieldValueListModule,
        DialogModule,
        LogLinesListModule,
        FieldRequestStatusModule,
        FieldRequestValueModule,
        FieldProblemTypeModule,
        PanelModule,
        ToggleButtonModule,
        DataStatusStatsPanelModule,
        DropdownModule,
        PaginatorModule,
        TabViewModule,
        JsonViewerModule,
        NitroEnvBannerModule,
        NitroUserModule,
        AccountingStatisticsDataModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
}
