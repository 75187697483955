import {Component, OnInit} from '@angular/core';
import {WsNitroUser} from "@fiscalteam/nitro-domain-client";
import {NitroLoggedUserService} from "@fiscalteam/ngx-nitro-services";
import {EMPTY, Observable} from "rxjs";
import {MenuItem} from "primeng/api";
import {ShellMenuService} from "../../shell-menu.service";
import {VERSION} from "../../../../version";
import {Router} from "@angular/router";

@Component({
  selector: 'adm-app-shell-menu',
  templateUrl: './app-shell-menu.component.html',
  styleUrls: ['./app-shell-menu.component.scss']
})
export class AppShellMenuComponent implements OnInit {
  loggedUser?: WsNitroUser;
  menu$: Observable<MenuItem[]> = EMPTY;
  version = VERSION;

  constructor(
    private nitroLoggedUserService: NitroLoggedUserService,
    private menuService: ShellMenuService,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.menu$ = this.menuService.menu$;
    this.loggedUser = await this.nitroLoggedUserService.getLoggedUser$();
  }

  async onLogoutClick() {
    await this.nitroLoggedUserService.logout();
  }

  onTitleClick(){
    this.router.navigate(['/'])
  }
  onMenuItemClick(subItem: MenuItem) {
    this.menuService.updateMenuItem$(subItem, false);
  }

  protected readonly JSON = JSON;
}
