import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {ErrorComponent, ErrorRouteComponent, ifUserAuthenticated, LogoutRouteComponent} from "@fiscalteam/ngx-nitro-services";
import {AppShellComponent} from "./shell/app-shell/app-shell.component";
import {AdminFrontRoutes} from "./shell/admin-front-routes";


const routes: Route[] = [
  {
    path: '',
    // Authenticated users: requires admin role
    component: AppShellComponent,
    canActivate: [ifUserAuthenticated(['admin'])],
    children: AdminFrontRoutes
  }, {
    // Authenticated users without the required role, isplay the logout page
    path: 'logout',
    component: LogoutRouteComponent,
  }, {
    // Authenticated users can see the fatal error pages
    path: 'error',
    component: ErrorRouteComponent,
  },{
    // Authenticated users can see the fatal error pages
    path: 'auth/error',
    component: ErrorComponent,
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload',
    // enableTracing: true,
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
