import {CustomerTransactionFilterProperties, CustomerTransactionFilterProperty, FilterPropertyValue} from "@fiscalteam/ngx-nitro-services";
import {WsCustomerTransactionSearch, WsCustomerTransactionStatus} from "@fiscalteam/nitro-domain-client";

export const WellKnowCustomerTransactionFilters: any & Record<string, FilterPropertyValue<CustomerTransactionFilterProperty, WsCustomerTransactionSearch, any>[]> = {
  /**
   * A filter matchign all transactions
   */
  all: [],
  /**
   * A filter matching "transaction status == TO_VALIDATE"
   */
  statut: [
    {
      property: CustomerTransactionFilterProperties.anyStatus,
      filterValue :[WsCustomerTransactionStatus.ToValidate]
    }
  ],
  /**
   * A filter matching problematic transactions
   */
  problematic: [
    {
      property: CustomerTransactionFilterProperties.dataProblematic,
      filterValue :true
    }
  ],

}
