<div class="field-request-details-form w-full ">

  <form *ngIf="value" class="adm-p-field-form flex align-items-start justify-content-center">

    <div class="col base-info-col w-30rem inline-block mx-3">

      <h2>Informations de base</h2>

      <div class="p-field w-full">
        <label for="creationTime">Date de création</label>
        <div class="w-full" id="creationTime">
          {{ value.creationTime | date:'full' }}
        </div>
      </div>

      <div class="p-field w-full">
        <label for="displayOrder">Ordre d'affichage</label>
        <div class="w-full" id="displayOrder">
          {{ value.displayOrder | number:'.0-0' }}
        </div>
      </div>

      <div class="p-field w-full">
        <label for="requiredIdentificationsCount">Indexations requises</label>
        <div class="w-full" id="requiredIdentificationsCount">
          {{ value.requiredIdentificationsCount | number:'.0-0' }}
        </div>
      </div>

      <div class="p-field w-full">
        <label for="requiredMatchingValuesCount">Matchings requis</label>
        <div class="w-full" id="requiredMatchingValuesCount">
          {{ value.requiredMatchingValuesCount | number:'.0-0' }}
        </div>
      </div>

      <div class="p-field w-full">
        <label for="targetGroup">Groupe cible</label>
        <div class="w-full" id="targetGroup">
          {{ value.targetGroup }}
        </div>
      </div>

      <div class="p-field w-full">
        <label for="requestStatus">Statut</label>
        <div class="w-full" id="requestStatus">
          <nit-field-request-status [value]="value.requestStatus || undefined">
          </nit-field-request-status>
        </div>
      </div>


      <div class="p-field w-full">
        <label for="identifiedValue">Valeur identifiée</label>
        <nit-field-request-value [requestRef]="{id: value.id!}"
                                 id="identifiedValue"
        ></nit-field-request-value>
      </div>

      <div *ngIf="value.fieldProblemType" class="p-field w-full">
        <label for="fieldProblemType">Type de problème</label>
        <nit-field-problem-type [fieldType]="(documentField$ | async)?.fieldType || undefined"
                                [problemType]="value.fieldProblemType"
                                id="fieldProblemType"
        ></nit-field-problem-type>
      </div>

    </div>
  </form>
</div>
