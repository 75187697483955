import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {WsDocumentField, WsFieldIdentificationRequest} from "@fiscalteam/nitro-domain-client";
import {BehaviorSubject, Subscription} from "rxjs";
import {DocumentFieldService} from "@fiscalteam/ngx-nitro-services";

@Component({
  selector: 'adm-field-request-details-form',
  templateUrl: './field-request-details-form.component.html',
  styleUrls: ['./field-request-details-form.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FieldRequestDetailsFormComponent,
    multi: true
  }]
})
export class FieldRequestDetailsFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  readOnly: boolean = false;

  value: WsFieldIdentificationRequest | undefined;
  documentField$ = new BehaviorSubject<WsDocumentField | undefined>(undefined);

  private documentFieldSubscription?: Subscription;

  private onChange?: Function;
  private onTouched?: Function;

  constructor(
    private documentFieldService: DocumentFieldService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.documentFieldSubscription) {
      this.documentFieldSubscription.unsubscribe();
    }
  }

  writeValue(obj: WsFieldIdentificationRequest | undefined): void {
    this.value = obj;
    if (this.documentFieldSubscription) {
      this.documentFieldSubscription.unsubscribe();
    }

    if (obj && obj.documentFieldCode) {
      this.documentFieldSubscription = this.documentFieldService.getField$(obj.documentFieldCode)
        .subscribe(f => this.documentField$.next(f));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  fireChanges() {
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}
