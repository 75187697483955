import {Route} from "@angular/router";
import {NitroUserRouteComponent} from "./nitro-user-route/nitro-user-route.component";
import {NitroUserNewRouteComponent} from "./nitro-user-new-route/nitro-user-new-route.component";


export const NitroUserRoutes: Route[] =[
  {
    path:'',
    pathMatch:'full',
    redirectTo: 'list'
  },
  {
    path:'list',
    component: NitroUserRouteComponent,
  },
  {
    path:'new',
    component: NitroUserNewRouteComponent,
  }
]
