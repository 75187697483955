import {DefaultService, WsAccountingDataProblemSearch, WsAccountingDataProblemType, WsFieldProblemType} from "@fiscalteam/nitro-domain-client";
import {AccountingDataProblemFilterProperties, AccountingDataProblemFilterProperty, FilterPropertiesUtils, FilterPropertyValue} from "@fiscalteam/ngx-nitro-services";
import {map, Observable, shareReplay} from "rxjs";

export const WellKnownAccountingDataProblemsFilters: any & Record<string, FilterPropertyValue<AccountingDataProblemFilterProperty, WsAccountingDataProblemSearch, any>[]> = {
  all: [],
  unresolved: [
    {
      property: AccountingDataProblemFilterProperties.resolved,
      filterValue: false,
    }
  ],
  thirdPartiesToCreate: [
    {
      property: AccountingDataProblemFilterProperties.resolved,
      filterValue: false,
    },
    {
      property: AccountingDataProblemFilterProperties.problemType,
      filterValue: WsAccountingDataProblemType.ProblematicField,
    },
    {
      property: AccountingDataProblemFilterProperties.fieldRequestProblemType,
      filterValue: WsFieldProblemType.ThirdpartyDoesNotExists
    },
    {
      property: AccountingDataProblemFilterProperties.fieldRequestFieldCode,
      filterValue: "THIRPARTY_FROM;THIRPARTY_TO"
    }
  ],
  ibansToCreate: [
    {
      property: AccountingDataProblemFilterProperties.resolved,
      filterValue: false,
    },
    {
      property: AccountingDataProblemFilterProperties.problemType,
      filterValue: WsAccountingDataProblemType.ProblematicField,
    },
    {
      property: AccountingDataProblemFilterProperties.fieldRequestProblemType,
      filterValue: WsFieldProblemType.IbanDoesNotExist,
    },

  ],
  problematicField: [
    {
      property: AccountingDataProblemFilterProperties.problemType,
      filterValue: WsAccountingDataProblemType.ProblematicField,
    }
  ],
  inconsistentCustomerProblem: [
    {
      property: AccountingDataProblemFilterProperties.problemType,
      filterValue: WsAccountingDataProblemType.InconsistentCustomer,
    }
  ],
  unsupportedFileProblem: [
    {
      property: AccountingDataProblemFilterProperties.problemType,
      filterValue: WsAccountingDataProblemType.UnsupportedFile,
    }
  ]
}

export function countProblems$(api: DefaultService, filters: FilterPropertyValue<AccountingDataProblemFilterProperty, WsAccountingDataProblemSearch, any>[]): Observable<number> {
  const searchFitler = FilterPropertiesUtils.createSearchFilter(filters);
  return api.accountingDataProblemSearchPost(0, undefined, 0, undefined, searchFitler).pipe(
    map(r => r.totalCount || 0),
    shareReplay({bufferSize: 1, refCount: true})
  );
}
