<router-outlet (activate)="routeLoaded = true">
  <div *ngIf="!routeLoaded"
       class="flex align-items-center justify-content-center h-screen">
    <div class="flex-0">Chargement..</div>
  </div>
</router-outlet>
<div *ngIf="(backendAvailable$ | async) === false"
     class="absolute right-0 top-0 w-3rem h-3rem m-3  flex align-items-center justify-content-center"
     title="Le serveur ne répond pas"
>
  <i class="flex-0 p-3 w-3rem h-3rem pi pi-power-off bg-orange-300  hover:bg-orange-400 text-black border-circle cursor-pointer"
     (click)="onBackendUnavailableClick()"
  ></i>
</div>
<div class="banner">
  <nit-env-banner [showVersion]="true"
                  [version]="version"
  ></nit-env-banner>
</div>
