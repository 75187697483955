import {Component, OnInit} from '@angular/core';
import {EMPTY} from "rxjs";
import {AdminFrontSharedModule} from "../../shared/admin-front-shared.module";
import {AsyncPipe, NgIf} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {TabMenuModule} from "primeng/tabmenu";
import {TableNavigatorModule} from "@fiscalteam/ngx-nitro-services";
import {UserListComponent} from "../user-list/user-list.component";
import {FormsModule} from "@angular/forms";
import {NewUserFormInputModule} from "@fiscalteam/ngx-nitro-services";

@Component({
  selector: 'adm-nitro-user-new-route',
  standalone: true,
  imports: [
    AdminFrontSharedModule,
    AsyncPipe,
    NgIf,
    RouterOutlet,
    TabMenuModule,
    TableNavigatorModule,
    UserListComponent,
    NewUserFormInputModule,
    FormsModule,
    NgIf,
  ],
  templateUrl: './nitro-user-new-route.component.html',
  styleUrl: './nitro-user-new-route.component.scss'
})
export class NitroUserNewRouteComponent implements OnInit {

  protected readonly pageTitle$ = EMPTY;
  protected readonly tabMenu$ = EMPTY;

  ngOnInit(): void {
  }
}
