import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {CustomerContextProvider, CustomerDocumentContextProvider, CustomerDocumentRow, CustomerRow, CustomerTransactionContextProvider, CustomerTransactionRow, FinancialAccountContextProvider, FinancialProviderContextProvider, NitroRuleActionContextProvider, NitroRuleActionRow, NitroRuleContextProvider, NitroRuleRow, NitroRuleService, ThirdpartyContextProvider, ThirdpartyEntityContextProvider, ThirdPartyEntityRow, ThirdPartyRow, TrusteeContextProvider, TrusteeRow} from "@fiscalteam/ngx-nitro-services";
import {WsFinancialAccount, WsFinancialAccountProvider} from "@fiscalteam/nitro-domain-client";


@Injectable()
export class AdminFrontContextService {

  contextTrustee$: BehaviorSubject<TrusteeRow | undefined>;
  contextCustomer$: BehaviorSubject<CustomerRow | undefined>;

  contextRuleAction$: BehaviorSubject<NitroRuleActionRow | undefined>;
  contextRule$: Observable<NitroRuleRow | undefined>;

  contextThirdparty$: BehaviorSubject<ThirdPartyRow | undefined>;
  contextThirdpartyEntity$: BehaviorSubject<ThirdPartyEntityRow | undefined>;

  contextCustomerDocument$: BehaviorSubject<CustomerDocumentRow | undefined>;
  contextCustomerTransaction$: BehaviorSubject<CustomerTransactionRow | undefined>;

  contextFinancialAccount$: BehaviorSubject<WsFinancialAccount | undefined>;
  contextFinancialAccountProvider$: BehaviorSubject<WsFinancialAccountProvider | undefined>;

  constructor(
    private trusteeContextProvider: TrusteeContextProvider,
    private customerContextProvider: CustomerContextProvider,
    private ruleActionContextProvider: NitroRuleActionContextProvider,
    private ruleContextProvider: NitroRuleContextProvider,
    private thirdpartyContextProvider: ThirdpartyContextProvider,
    private thirdpartyEntityContextProvider: ThirdpartyEntityContextProvider,
    private customerDocumentContextProvider: CustomerDocumentContextProvider,
    private financialAccountContextProvider: FinancialAccountContextProvider,
    private financialProviderContextProvider : FinancialProviderContextProvider,
    private customerTransactionContextProvider: CustomerTransactionContextProvider,
  ) {
    // TODO: the effective trustee may be obtained from other trustee-owned resources in the context
    this.contextTrustee$ = this.trusteeContextProvider.trusteeRow$;
    this.contextCustomer$ = this.customerContextProvider.customerRow$;

    this.contextRuleAction$ = this.ruleActionContextProvider.nitroRuleActionRow$;
    this.contextRule$ = this.ruleContextProvider.nitroRuleRow$;

    this.contextThirdparty$ = this.thirdpartyContextProvider.thirdpartyRow$;
    this.contextThirdpartyEntity$ = this.thirdpartyEntityContextProvider.thirdpartyEntityRow$;

    this.contextCustomerDocument$ = this.customerDocumentContextProvider.customerDocumentRow$;
    this.contextCustomerTransaction$ = this.customerTransactionContextProvider.customerTransactionRow$;

    this.contextFinancialAccount$ = this.financialAccountContextProvider.financialAccountRow$;
    this.contextFinancialAccountProvider$ = this.financialProviderContextProvider.financialAccountProviderRow$;

  }

  /**
   * Create a router link matching the trustee and customer context at the time of calling this method.
   */
  createTrusteeCustomerContextRouterLinkPrefix(): any[] {
    const link: any[] = ['/'];
    const trusteeRow = this.contextTrustee$.getValue();
    if (trusteeRow && trusteeRow.wsTrustee) {
      link.push('trustee', trusteeRow.wsTrustee.id);
    }
    const customerRow = this.contextCustomer$.getValue();
    if (customerRow && customerRow.wsCustomer) {
      link.push('customer', customerRow.wsCustomer.id);
    }
    return link;
  }

  setTestFinancialAccountProvider(data: WsFinancialAccountProvider): void {
    this.contextFinancialAccountProvider$.next(data);
  }
}
