import {AdminFrontConfigKeys} from "./admin-front-config-keys";
import {ConfigKeyValues} from "@fiscalteam/ngx-nitro-services/lib/services/config/nitro-config-key-values";

/**
 * Default value, hardcoded in the environment files, but declared only once here.
 *
 * Note that some of those features are switched on/off depending on backend-provided NitroFeatures.
 * This is done in the admin front config service, rather than declaratively here.
 */
export const DefaultAdminFrontConfigs: ConfigKeyValues<AdminFrontConfigKeys | string, boolean | string | undefined> = {
  "codabox.importer.api.uri": undefined,
  "menu.thirdparty.visible": true,
  "menu.thirdparty.toValidate.visible": true,
  "menu.thirdparty.config.visible": true,
  "menu.data.visible": true,
  "menu.data.dashboard.visible": true,
  "menu.document.visible": true,
  "menu.customerBankAccount.visible": true,
  "menu.customerBankAccountAll.visible": true,
  "menu.customerBankAccountTovalidate.visible": true,
  "menu.customerBankAccountProblematics.visible": true,
  "menu.accountingDataProblem.visible": true,
  "menu.accountingDataProblem.thirdpartyToCreate.visible": true,
  "menu.accountingDataProblem.ibanToCreate.visible": true,
  "menu.config.visible": true,
  "menu.config.batch.visible": true,
  "menu.config.businessSector.visible": true,
  "menu.config.filters.visible": true,
  "menu.config.configs.visible": true,
  "menu.config.rules.visible": true,
  "menu.config.rules.SuggestDocumentTypes.visible": true,
  "menu.config.rules.UseFallbackDirection.visible": true,
  "menu.config.rules.UseFallbackThirdparty.visible": true,
  "menu.config.rules.SetDocumentBookable.visible": false,
  "menu.config.rules.SetGlaccount.visible": true,
  "menu.config.rules.SetThirdpartyOverride.visible": true,
  "menu.config.rules.SetProblematic.visible": true,
  "menu.config.docTypeSelection.visible": true,
  "menu.customer.visible": true,
  "menu.trustee.visible": true,
  "businesssector.actions.delete.visible": true,
  "data.configuration.visible": true,
  "data.export.visible": true,
  "document.details.exportedDocumentForm.visible": true,
  "document.actions.upload.visible": true,
  "document.actions.replaceThirdParty.visible": true,
  "document.actions.refresh.visible": true,
  'doctypecollection.actions.create.visible': true,
  'doctypecollection.actions.delete.visible': true,
  'filters.actions.create.visible': true,
  'filters.actions.delete.visible': true,
  'problem.actions.delete.visible': true,
  "transaction.actions.refresh.visible": true,
  "transaction.actions.uploadCoda.visible": true,
  "transaction.actions.replaceThirdParty.visible": true,
  "thirdparty.actions.create.visible": true,
  "thirdparty.actions.importVat.visible": true,
  "thirdparty.actions.replaceThirdParty.visible": true,
  "thirdparty.actions.validate.visible": true
}
