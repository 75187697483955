import {DefaultService, WsThirdPartySearch} from "@fiscalteam/nitro-domain-client";
import {FilterPropertiesUtils, FilterPropertyValue, ThirdpartyFilterProperty} from "@fiscalteam/ngx-nitro-services";
import {map, Observable, shareReplay} from "rxjs";

export const WellKnownThirdpartyFilters: any & Record<string, FilterPropertyValue<ThirdpartyFilterProperty, WsThirdPartySearch, any>[]> = {
  all: [],
}

export function countThirdParties$(api: DefaultService, filters: FilterPropertyValue<ThirdpartyFilterProperty, WsThirdPartySearch, any>[]): Observable<number> {
  const searchFitler = FilterPropertiesUtils.createSearchFilter(filters);
  return api.thirdPartySearchPost(0, undefined, 0, undefined, searchFitler).pipe(
    map(r => r.totalCount || 0),
    shareReplay({bufferSize: 1, refCount: true})
  );
}
