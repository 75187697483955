import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {NitroUserRoutes} from "./nitro-user-routes";


@NgModule({
  imports:[RouterModule.forChild(NitroUserRoutes)],
  exports: [RouterModule]
})


export class NitroUserRoutingModule{

}
