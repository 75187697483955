import {DefaultService, WsCustomerDocumentSearch, WsCustomerDocumentStatus} from "@fiscalteam/nitro-domain-client";
import {CustomerDocumentFilterProperties, CustomerDocumentFilterProperty, FilterPropertiesUtils, FilterPropertyValue} from "@fiscalteam/ngx-nitro-services";
import {map, Observable, shareReplay} from "rxjs";

const DOCUMENT_STATUS_LIST_NON_DRAFT = [
  WsCustomerDocumentStatus.Created,
  WsCustomerDocumentStatus.Sortable,
  WsCustomerDocumentStatus.Sorted,
  WsCustomerDocumentStatus.Indexed,
  WsCustomerDocumentStatus.Configured,
  WsCustomerDocumentStatus.ToValidate,
  WsCustomerDocumentStatus.ToExport,
  WsCustomerDocumentStatus.Completed,
];

export const WellKnownCustomerDocumentFilters: any & Record<string, FilterPropertyValue<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]> = {
  all: [
    {
      property: CustomerDocumentFilterProperties.anyStatus,
      filterValue: DOCUMENT_STATUS_LIST_NON_DRAFT,
    }
  ],
  problematic: [
    {
      property: CustomerDocumentFilterProperties.dataProblematic,
      filterValue: true,
    }
  ],
  status_to_validate: [
    {
      property: CustomerDocumentFilterProperties.anyStatus,
      filterValue: [WsCustomerDocumentStatus.ToValidate]
    }
  ],
}


export function countDocuments$(api: DefaultService, filters: FilterPropertyValue<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]): Observable<number> {
  const searchFitler = FilterPropertiesUtils.createSearchFilter(filters);
  return api.customerDocumentSearchPost(0, undefined, 0, undefined, searchFitler).pipe(
    map(r => r.totalCount || 0),
    shareReplay({bufferSize: 1, refCount: true})
  );
}
